import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatPhone'
})
export class FormatPhonePipe implements PipeTransform {

  transform(rawPhoneNum: string): string {

    if (!rawPhoneNum) { return 'N/A'}

    if (rawPhoneNum.length < 10) {
      return `[Invalid number: should be 10 digits long: ${rawPhoneNum}]`;
    }

    const areaCodeStr = rawPhoneNum.slice(0, 3);
    const midSectionStr = rawPhoneNum.slice(3, 6);
    const lastSectionStr = rawPhoneNum.slice(6);

    return `${areaCodeStr}-${midSectionStr}-${lastSectionStr}`;
  }

}
