import { Injectable } from '@angular/core';
import {AccommodationChoices} from "../../model/types/accommodation-choices";
import {BehaviorSubject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class AccommChoicesCacheService {

  // Stores accommodation instructions coming from CIS database tables.
  private accommodationChoices: AccommodationChoices;

  private cache: AccommodationChoices;
  public cache$ = new BehaviorSubject<AccommodationChoices>(null);

  set(data: AccommodationChoices) {
    this.cache = data;
    this.cache$.next(data);
  }

  get() {
    return this.cache;
  }

  constructor() { }
}
