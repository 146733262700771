<div class="cis-submission-details">
  <!-- Masthead -->
  <div class="row">
    <!-- Masthead Header -->
    <div class="page-header col-7">
      <div class="masthead-header">
        <h1>Review CIS Submission Details</h1>
      </div>
    </div>
    <!-- Masthead Tools -->
    <div class="page-tools col-5">
      <div class="bold">Tools</div>
      <app-act-log-masthead [cisSubmission]="cisSubmission" [loggedInUser]="loggedInUser"></app-act-log-masthead> |
      <app-student-search-masthead></app-student-search-masthead> |
      <app-provision-student-masthead></app-provision-student-masthead>
    </div>
  </div>

  <!-- Display CIS submission general info -->
  <div>
    <app-cis-sub-gen-info [cisSubmission]="cisSubmission"></app-cis-sub-gen-info>
  </div>


  <!-- === Instructor Contact Information Card === -->
  <div class="margin-top-2-rem">
    <div class="row">
      <div class="col-9">
        <div class="card">
          <div class="card-body">
            <h2 class="card-title">Instructor Contact Information</h2>
            <p class="card-text">

              <!-- Contacts Removed -->
              <div class="old-value fit-content margin-bottom-2-rem" *ngIf="cisSubmission._accommInstrChanges?.contactsDeleted?.length">
                  <div class="fw-bold">Removed:</div>
                    <ul>
                      <li *ngFor="let deletedContact of cisSubmission._accommInstrChanges.contactsDeleted">
                                <span class="old-value">{{deletedContact.email}}</span>
                      </li>
                    </ul>
              </div>

            <div *ngFor="let contactInfo of cisSubmission.accommodationContactInfos, index as i;">
              <!-- Contact # -->
              <div class="margin-top-4-rem">
                <h3>Contact {{i + 1}}
                  <!-- Mark newly added contacts -->
                  <span class="old-value margin-left-1rem" *ngIf="isContactAdded(contactInfo.email)">
                    <span class="fw-bold">Added</span>
                  </span>
                </h3>
              </div>
              <!-- Name -->
              <div> <span class="fw-bold">Name:</span>
                            {{contactInfo.name ? contactInfo.name : contactInfo.firstname + ' ' + contactInfo.lastname}}
                <!-- previous value -->
                <span class="old-value margin-left-1rem"
                      *ngIf="cisSubmission?._accommInstrChanges['contactChangedMap'][contactInfo.email]?.name">
                   <span class="fw-bold">Previously:</span>
                  {{cisSubmission?._accommInstrChanges['contactChangedMap'][contactInfo.email]?.name?.previousVal}}
                </span>
              </div>
              <!-- Email -->
              <div class="margin-top-2-rem"> <span class="fw-bold">Email:</span> {{contactInfo.email}} </div>
              <!-- Phone -->
              <div class="margin-top-2-rem"> <span class="fw-bold">Phone:</span>
                                                           {{contactInfo.phone ? (contactInfo.phone | formatPhone) : 'N/A'}}
                <!-- previous value -->
                <span class="old-value margin-left-1rem"
                      *ngIf="cisSubmission?._accommInstrChanges['contactChangedMap'][contactInfo.email]?.phone">
                   <span class="fw-bold">Previously:</span>
                         {{cisSubmission?._accommInstrChanges['contactChangedMap'][contactInfo.email]?.phone?.previousVal | formatPhone}}
                </span>
              </div>
              <!-- Receive text -->
              <div class="margin-top-2-rem">
                    <span class="fw-bold">Receive text: </span>{{contactInfo.receiveText === true ? 'Yes' : 'No'}}
                <!-- previous value -->
                <span class="old-value"
                      *ngIf="cisSubmission?._accommInstrChanges['contactChangedMap'][contactInfo.email]?.receiveText">
                   <span class="fw-bold">Previously:</span>
                  {{convertBooleanStr(cisSubmission?._accommInstrChanges['contactChangedMap'][contactInfo.email]?.receiveText?.previousVal)}}
                </span>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- === Assessment Details === -->
  <div class="margin-top-2-rem">
    <div class="row">
      <div class="col-9">
        <div class="card">
          <div class="card-body">
            <h3 class="card-title">Assessment Details</h3>
            <p class="card-text">

            <!-- Assessment held on initial date -->
            <div><span class="fw-bold">Assessment held on initial date
                                  ({{cisSubmission.assessmentStartDate | date: 'yyyy-MM-dd'}})</span>:
                                             {{cisSubmission.assessmentHeldInd === true ? 'Yes' : 'No'}}
            <!-- previous value -->
            <span class="old-value margin-left-1rem" *ngIf="propValMap?.assessmentHeldInd">
                  <span class="fw-bold">Previously:</span>
                                             {{convertBooleanStr(propValMap?.assessmentHeldInd?.previousVal)}}
            </span>
          </div>

            <!-- Assessment on a Different Date or No Upcoming Assessment -->
            <div class="margin-top-2-rem margin-left-2rem">
              <span class="fw-bold">Assessment on a Different Date or No Upcoming Assessment</span>:
                                           {{cisSubmission.upcomingAssessmentInd === true ? 'Yes' :
                                                   cisSubmission.upcomingAssessmentInd === false ? 'No' : 'N/A'}}
              <!-- previous value -->
              <span class="old-value margin-left-1rem" *ngIf="propValMap?.upcomingAssessmentInd">
                     <span class="fw-bold">Previously:</span>
                                             {{convertBooleanStr(propValMap?.upcomingAssessmentInd?.previousVal)}}
              </span>
            </div>

            <!-- Explanation if no assessment -->
            <div class="margin-top-2-rem margin-left-4rem">
                 <span class="fw-bold">Explanation for No Upcoming Assessment</span>: <br>
                                                     {{cisSubmission.explanationForNoAssessmentHeld || 'N/A'}}
              <!-- previous value -->
              <div class="margin-top-1-rem" *ngIf="propValMap?.explanationForNoAssessmentHeld">
                <div class="old-value fit-content">
                   <span class="fw-bold">Previously:</span>
                                              {{propValMap?.explanationForNoAssessmentHeld.previousVal || 'N/A'}}
                </div>
              </div>
            </div>


              <!-- *** Class Writing Schedule *** -->
              <div class="margin-top-4-rem"> <h3>Class Writing Schedule</h3> </div>

              <!-- Instructor Assessment Start Date -->
              <div><span class="fw-bold">Assessment Start Date:</span>
                                             {{cisSubmission.assessmentStartDate | date: 'yyyy-MM-dd'}}
                <!-- previous value -->
                <span class="old-value margin-left-1rem" *ngIf="propValMap?.assessmentStartDate">
                   <span class="fw-bold">Previously:</span>
                                            {{!propValMap?.assessmentStartDate.previousVal ? 'N/A'
                                             : propValMap?.assessmentStartDate.previousVal | date: 'yyyy-MM-dd'}}
                </span>
              </div>

              <!-- Instructor Assessment End Date -->
              <div class="margin-top-2-rem"><span class="fw-bold">Assessment End Date</span>:
                                             {{!cisSubmission.assessmentEndDate ?
                                                'N/A' : cisSubmission.assessmentEndDate | date: 'yyyy-MM-dd'}}
                <!-- previous value -->
                <span class="old-value margin-left-1rem" *ngIf="propValMap?.assessmentEndDate">
                   <span class="fw-bold">Previously:</span>
                                              {{!propValMap?.assessmentEndDate.previousVal ? 'N/A'
                                               : propValMap?.assessmentEndDate.previousVal | date: 'yyyy-MM-dd'}}
                </span>
              </div>
              <!-- Instructor Assessment Start Time -->
              <div class="margin-top-2-rem">
                <span class="fw-bold">Assessment Start Time</span>:
                                             {{cisSubmission.assessmentStartTime | date:'h:mm a'}}
                <!-- previous value -->
                <span class="old-value margin-left-1rem" *ngIf="propValMap?.assessmentStartTime">
                   <span class="fw-bold">Previously:</span>
                                              {{!propValMap?.assessmentStartTime.previousVal ? 'N/A'
                                                  : propValMap?.assessmentStartTime.previousVal | date:'h:mm a'}}
                </span>
              </div>
              <!-- Instructor Assessment End Time -->
              <div class="margin-top-2-rem">
                <span class="fw-bold">Assessment End Time</span>:
                                             {{cisSubmission.assessmentEndTime | date:'h:mm a' || 'N/A'}}
                <!-- previous value -->
                <span class="old-value margin-left-1rem" *ngIf="propValMap?.assessmentEndTime">
                   <span class="fw-bold">Previously:</span>
                                              {{!propValMap?.assessmentEndTime.previousVal ? 'N/A'
                                                    : propValMap?.assessmentEndTime.previousVal | date:'h:mm a'}}
                </span>
              </div>
              <!-- Instructor Assessment Duration -->
              <div class="margin-top-2-rem"><span class="fw-bold">Duration</span>:
                          {{!cisSubmission.assessmentDuration ? 'N/A' : cisSubmission.assessmentDuration + 'min'}}
                <!-- previous value -->
                <span class="old-value margin-left-1rem" *ngIf="propValMap?.assessmentDuration">
                   <span class="fw-bold">Previously:</span>
                                              {{!propValMap?.assessmentDuration.previousVal ? 'N/A'
                                                       : propValMap?.assessmentDuration.previousVal  + 'min'}}
                </span>
              </div>
              <!-- Timing Type (Timing and Submission) -->
              <div class="margin-top-2-rem"><span class="fw-bold">Timing Type</span>:
                                 {{!cisSubmission.assessmentTimingInd ?
                                          'N/A' : Utils.assessmentTimingIndMap.get(cisSubmission.assessmentTimingInd)}}
                <!-- previous value -->
                <span class="old-value margin-left-1rem" *ngIf="propValMap?.assessmentTimingInd">
                   <span class="fw-bold">Previously:</span>
                                       {{!propValMap?.assessmentTimingInd.previousVal ? 'N/A'
                                       : Utils.assessmentTimingIndMap.get(propValMap?.assessmentTimingInd.previousVal)}}
                </span>
              </div>

            <!-- *** Online vs. In-Person *** -->
            <div class="margin-top-4-rem"> <h3>Online vs. In-Person</h3> </div>

            <!-- Online Assessment -->
            <div><span class="fw-bold">Online Assessment:</span>
                                             {{cisSubmission.assessmentOnlineInd === true ? 'Yes' : 'No'}}
              <!-- previous value -->
              <span class="old-value margin-left-1rem" *ngIf="propValMap?.assessmentOnlineInd">
                 <span class="fw-bold">Previously:</span>
                                            {{convertBooleanStr(propValMap?.assessmentOnlineInd.previousVal)}}
              </span>
            </div>

            <!-- How is the assessment completed? -->
            <div class="margin-top-2-rem margin-left-2rem">
              <span class="fw-bold">How is the assessment completed?:</span>
                                      {{Utils.onlineVsInPersonMap.get(cisSubmission.__onlineVsInPerson)}}
              <!-- previous value -->
              <span class="old-value margin-left-1rem" *ngIf="propValMap?.__onlineVsInPerson">
                   <span class="fw-bold">Previously:</span>
                        {{!propValMap?.__onlineVsInPerson ? 'N/A'
                               : Utils.onlineVsInPersonMap.get(propValMap?.__onlineVsInPerson.previousVal)}}
                </span>
            </div>

            <!-- Online Type -->
            <div class="margin-top-2-rem margin-left-2rem">
              <span class="fw-bold">Online Type:</span>
                             {{onlineAssessmentTypeMap.get(cisSubmission.onlineAssessmentTypeId)?.onlineAssessmentType || 'N/A'}}
              <!-- previous value -->
              <span class="old-value margin-left-1rem" *ngIf="propValMap?.onlineAssessmentTypeId">
                 <span class="fw-bold">Previously:</span>
                         {{!propValMap?.onlineAssessmentTypeId?.previousVal ? 'N/A'
                              : onlineAssessmentTypeMap.get(parseInt(propValMap?.onlineAssessmentTypeId?.previousVal))?.onlineAssessmentType}}
              </span>
            </div>

            <!-- Specify Type, if other -->
            <div class="margin-top-2-rem margin-left-4rem">
               <span class="fw-bold">Specify Type, if Other:</span> <br>
                        {{cisSubmission.onlineAssessmentTypeDetails || 'N/A'}}
              <!-- previous value -->
              <div class="margin-top-1-rem" *ngIf="propValMap?.onlineAssessmentTypeDetails">
                <div class="old-value fit-content">
                   <span class="fw-bold">Previously:</span>
                                              {{propValMap?.onlineAssessmentTypeDetails.previousVal || 'N/A'}}
                </div>
              </div>
            </div>

            <!-- Quercus/Crowdmark Consent -->
            <div class="margin-top-2-rem margin-left-4rem">
              <span class="fw-bold">Quercus/Crowdmark Consent:</span>
                                  {{(cisSubmission.onlineAssmtPermissionToAtsInd === true ? 'Yes' : 'No') || 'N/A'}}
              <!-- previous value -->
              <span class="old-value margin-left-1rem" *ngIf="propValMap?.onlineAssmtPermissionToAtsInd">
                 <span class="fw-bold">Previously:</span>
                                       {{convertBooleanStr(propValMap?.onlineAssmtPermissionToAtsInd.previousVal)}}
              </span>
            </div>

            <!-- Is the online assessment published? -->
            <div class="margin-top-2-rem margin-left-6rem">
              <span class="fw-bold">Is the online assessment published?:</span>
                                  {{(cisSubmission.onlineAssmtPublishedInd === true ? 'Yes' : 'No') || 'N/A'}}
              <!-- previous value -->
              <span class="old-value margin-left-1rem" *ngIf="propValMap?.onlineAssmtPublishedInd">
                 <span class="fw-bold">Previously:</span>
                                            {{convertBooleanStr(propValMap?.onlineAssmtPublishedInd.previousVal)}}
              </span>
            </div>

            <!-- Name of Online Assessment -->
            <div class="margin-top-2-rem margin-left-8rem">
               <span class="fw-bold">Name of Online Assessment:</span> <br>
                                            {{cisSubmission.onlineAssmtTestName || 'N/A'}}
              <!-- previous value -->
              <div class="margin-top-1-rem" *ngIf="propValMap?.onlineAssmtTestName">
                <div class="old-value fit-content">
                   <span class="fw-bold">Previously:</span>
                                               {{propValMap?.onlineAssmtTestName.previousVal || 'N/A'}}
                </div>
              </div>
            </div>

            <!-- Lecture Before or After -->
            <div class="margin-top-2-rem margin-left-2rem">
              <span class="fw-bold">Lecture Before or After:</span>
                        {{Utils.lectureBeforeOrAfterMap.get(cisSubmission.lecBeforeOrAfterAssessmentInd) || 'N/A'}}
              <!-- previous value -->
              <span class="old-value margin-left-1rem" *ngIf="propValMap?.lecBeforeOrAfterAssessmentInd">
                 <span class="fw-bold">Previously:</span>
                            {{!propValMap?.lecBeforeOrAfterAssessmentInd.previousVal ? 'N/A'
                     : Utils.lectureBeforeOrAfterMap.get(propValMap?.lecBeforeOrAfterAssessmentInd.previousVal)}}
              </span>
            </div>

              <!-- Time to Return to Class, if Yes -->
              <div class="margin-top-2-rem margin-left-4rem">
                <span class="fw-bold">Time to Return to Class, if After:</span>
                                 {{!cisSubmission.lectureAfterReturnToClassBy ?
                                      'N/A' : cisSubmission.lectureAfterReturnToClassBy | date: 'h:mm a'}}
                <!-- previous value -->
                <span class="old-value margin-left-1rem" *ngIf="propValMap?.lectureAfterReturnToClassBy">
                   <span class="fw-bold">Previously:</span>
                                           {{!propValMap?.lectureAfterReturnToClassBy ? 'N/A'
                                         : propValMap?.lectureAfterReturnToClassBy.previousVal | date: 'h:mm a'}}
                </span>
              </div>

            <!-- Group Component -->
            <div class="margin-top-2-rem margin-left-2rem">
              <span class="fw-bold">Group Component:</span>
                                   {{cisSubmission.groupComponentForAssessmentInd === true ? 'Yes' :
                                          cisSubmission.groupComponentForAssessmentInd === false ? 'No' : 'N/A'}}
              <!-- previous value -->
              <span class="old-value margin-left-1rem" *ngIf="propValMap?.groupComponentForAssessmentInd">
                   <span class="fw-bold">Previously:</span>
                                      {{convertBooleanStr(propValMap?.groupComponentForAssessmentInd.previousVal)}}
                </span>
            </div>

              <!-- Time to Return to Class, if Yes -->
              <div class="margin-top-2-rem margin-left-4rem">
                <span class="fw-bold">Time to Return to Class, if Yes:</span>
                             {{!cisSubmission.groupComponentReturnToClassBy ?
                                     'N/A' : cisSubmission.groupComponentReturnToClassBy | date: 'h:mm a'}}
                <!-- previous value -->
                <span class="old-value margin-left-1rem" *ngIf="propValMap?.groupComponentReturnToClassBy">
                   <span class="fw-bold">Previously:</span>
                  {{!propValMap?.groupComponentReturnToClassBy.previousVal ? 'N/A'
                                       : propValMap?.groupComponentReturnToClassBy.previousVal | date: 'h:mm a'}}
                </span>
              </div>

            <!-- Delivery Details of Test Material (After the Assessment) ??? assessmentDetailsForAts-->
            <div class="margin-top-2-rem margin-left-2rem">
                                <span class="fw-bold">Delivery Details of Test Material (After the Assessment):</span>
                                          {{Utils.deliveryInstructionMap.get(cisSubmission.deliveryInstruction) || 'N/A'}}
              <!-- previous value -->
              <span class="old-value margin-left-1rem" *ngIf="propValMap?.deliveryInstruction">
                 <span class="fw-bold">Previously:</span>
                                            {{propValMap?.deliveryInstruction.previousVal || 'N/A'}}
              </span>
            </div>

            <!-- Assessment Location -->
            <div class="margin-top-2-rem margin-left-2rem">
              <span class="fw-bold">Assessment Location:</span> <br>
                                                        {{cisSubmission.assessmentLocation || 'N/A'}}
              <!-- previous value -->
              <div class="margin-top-1-rem" *ngIf="propValMap?.assessmentLocation">
                <div class="old-value fit-content">
                   <span class="fw-bold">Previously:</span>
                                              {{propValMap?.assessmentLocation.previousVal || 'N/A'}}
                </div>
              </div>
            </div>

            <!-- Special Instructions to Accommodated Testing Services -->
            <div class="margin-top-2-rem margin-left-2rem">
                              <span class="fw-bold">Special Instructions to Accommodated Testing Services:</span> <br>
                                                        {{cisSubmission.specialInstrForAssessmentHeld || 'N/A'}}
              <!-- previous value -->
              <div class="margin-top-1-rem" *ngIf="propValMap?.specialInstrForAssessmentHeld">
                <div class="old-value fit-content">
                   <span class="fw-bold">Previously:</span>
                                                 {{propValMap?.specialInstrForAssessmentHeld.previousVal || 'N/A'}}
                </div>
              </div>
            </div>

            <!--Assessment Details for Accommodated Testing Services -->
            <div class="margin-top-2-rem margin-left-2rem">
                              <span class="fw-bold">Assessment Details for Accommodated Testing Services:</span> <br>
                                                        {{cisSubmission.assessmentDetailsForAts || 'N/A'}}
              <!-- previous value -->
              <div class="margin-top-1-rem" *ngIf="propValMap?.assessmentDetailsForAts">
                <div class="old-value fit-content">
                   <span class="fw-bold">Previously:</span>
                                                      {{propValMap?.assessmentDetailsForAts.previousVal || 'N/A'}}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- === Accommodation Instructions Card === -->
  <div class="margin-top-2-rem">
    <div class="row">
      <div class="col-9">
        <div class="card">
          <div class="card-body">
            <h3 class="card-title">Accommodation Instructions</h3>
            <p class="card-text">

            <div> <span class="fw-bold">Special Instructions:</span> <br/>
                                            {{cisSubmission.specialInstructions || 'N/A'}}
              <!-- previous value -->
              <div class="margin-top-1-rem" *ngIf="propValMap?.specialInstructions">
                <div class="old-value fit-content">
                   <span class="fw-bold">Previously:</span>
                                                      {{propValMap?.specialInstructions.previousVal || 'N/A'}}
                </div>
              </div>
            </div>

            <!-- *** Aids *** -->
            <div class="margin-top-4-rem"> <h3>Aids</h3> </div>

            <!-- Aids Removed -->
            <div class="old-value fit-content margin-bottom-2-rem" *ngIf="cisSubmission._accommInstrChanges?.accommAidsDeleted?.length ">
              <div class="fw-bold">Removed:</div>
                <ul>
                  <li *ngFor="let deletedAid of cisSubmission._accommInstrChanges.accommAidsDeleted">
                    {{aidMap.get(deletedAid.aidTypeId)?.aidTypeCategory}} - {{aidMap.get(deletedAid.aidTypeId)?.aidType}}
                  </li>
                </ul>
            </div>

            <!-- Aids Permitted -->
            <div> <span class="fw-bold">Aids Permitted:</span>
                                            {{cisSubmission.aidPermittedInd === true ? 'Yes' : 'No'}}
              <!-- previous value -->
              <span class="old-value margin-left-1rem" *ngIf="propValMap?.aidPermittedInd">
                 <span class="fw-bold">Previously:</span> {{convertBooleanStr(propValMap?.aidPermittedInd.previousVal)}}
              </span>
            </div>
            <div *ngIf="cisSubmission.aidPermittedInd === true">
              <div *ngFor="let aid of cisSubmission.accommodationAids, index as i;">
                <!-- Aid #: Aid category - type -->
                <div class="margin-top-2-rem margin-left-2rem"> <span class="fw-bold">Aid {{i + 1}}:</span>
                         {{aidMap.get(aid.aidTypeId)?.aidTypeCategory}} - {{aidMap.get(aid.aidTypeId)?.aidType}}

                  <!-- Mark newly added aids -->
                  <span class="old-value margin-left-2rem" *ngIf="isAidAdded(aid.aidTypeId)">
                       <span class="fw-bold">Added</span>
                  </span>

                </div>
                  <!-- Specify Further Details (Optional - shows only for certain aids) -->
                  <div class="margin-top-2-rem margin-left-4rem">
                                                          <span class="fw-bold">Further Details, if Other:</span><br>
                                                                        {{aid?.aidDetails || 'N/A'}}
                    <!-- previous value -->
                    <div class="margin-top-1-rem"
                          *ngIf="cisSubmission?._accommInstrChanges['accommAidChangedMap'][aid.aidTypeId]?.aidDetails">
                      <div class="old-value fit-content">
                         <span class="fw-bold">Previously:</span>
                                    {{cisSubmission?._accommInstrChanges['accommAidChangedMap'][aid.aidTypeId].aidDetails.previousVal || 'N/A'}}
                      </div>
                    </div>
                  </div>
              </div>
            </div>

            <!-- *** Scantron *** -->
            <div class="margin-top-4-rem"> <h3>Scantron</h3> </div>

            <div class="margin-top-2-rem"> <span class="fw-bold">Scantron:</span>
                                  {{cisSubmission.scantronMultipleChoiceInd === true ? 'Yes' : 'No'}}
              <!-- previous value -->
              <span class="old-value margin-left-1rem" *ngIf="propValMap?.scantronMultipleChoiceInd">
                 <span class="fw-bold">Previously:</span>
                                          {{convertBooleanStr(propValMap?.scantronMultipleChoiceInd.previousVal)}}
              </span>
            </div>
              <!-- Scantron Version -->
              <div class="margin-top-2-rem margin-left-2rem">
                <span class="fw-bold">Scantron Version:</span>
                                   {{scantronMap.get(cisSubmission.scantronVersionId)?.scantronVersion || 'N/A'}}
                  <!-- previous value -->
                  <span class="old-value margin-left-1rem" *ngIf="propValMap?.scantronVersionId">
                   <span class="fw-bold">Previously:</span>
                                              {{!propValMap?.scantronVersionId?.previousVal ? 'N/A'
                                               : scantronMap.get(parseInt(propValMap?.scantronVersionId?.previousVal)).scantronVersion}}
                  </span>
              </div>
                <!-- Further Details, if Other -->
                <div class="margin-top-2-rem margin-left-4rem">
                  <span class="fw-bold">Further Details, if Other:</span><br>
                                   {{cisSubmission.scantronDetails || 'N/A'}}
                  <!-- previous value -->
                  <div class="margin-top-1-rem" *ngIf="propValMap?.scantronDetails">
                    <div class="old-value fit-content">
                       <span class="fw-bold">Previously:</span>
                                                   {{propValMap?.scantronDetails.previousVal || 'N/A'}}
                    </div>
                  </div>
                </div>

            <!-- Audio-Visual Component -->
            <div class="margin-top-4-rem"> <h3>Audio-Visual Component</h3> </div>

            <!-- Avs Removed -->
            <div class="old-value fit-content margin-bottom-2-rem" *ngIf="cisSubmission._accommInstrChanges?.accommAvsDeleted?.length ">
              <div class="fw-bold">Removed:</div>
                <ul>
                  <li *ngFor="let deletedAv of cisSubmission._accommInstrChanges?.accommAvsDeleted">
                    {{audiovisualMap.get(deletedAv.audiovisualCompId)?.audiovisualComp}}
                  </li>
                </ul>
            </div>

            <!-- === Audio-Visual required === -->
            <div> <span class="fw-bold">Audio-Visual Component Required:</span>
                                             {{cisSubmission.audiovisualRequiredInd === true ? 'Yes' : 'No'}}
              <!-- previous value -->
              <span class="old-value margin-left-1rem" *ngIf="propValMap?.audiovisualRequiredInd">
               <span class="fw-bold">Previously:</span>
                                          {{convertBooleanStr(propValMap?.audiovisualRequiredInd.previousVal)}}
              </span>
            </div>

            <div class="margin-top-2-rem" *ngFor=" let av of cisSubmission.accommodationAudiovisuals, index as i;">
              <!-- AV Component # -->
              <div class="margin-top-4-rem">
                <h4>AV Component {{i + 1}}
                  <!-- Mark newly added audiovisuals -->
                  <span class="old-value margin-left-1rem" *ngIf="isAudiovisualAdded(av.audiovisualCompId)">
                       <span class="fw-bold">Added</span>
                  </span>
                </h4>
              </div>
              <!-- Is the timing of the audiovisual component strict? -->
              <div> <span class="fw-bold">Strict Timing:</span>
                                                     {{av.audiovisualTimingStrictInd === true ? 'Yes' :
                                                   av.audiovisualTimingStrictInd === false ? 'No' : 'N/A'}} </div>
                <!-- What is the total length of the audiovisual component? -->
                <div class="margin-top-2-rem margin-left-2rem">
                                      <span class="fw-bold">Total length of AV component:</span> <br>
                                                                  {{av.audiovisualLength || 'N/A'}}
                  <!-- previous value -->
                  <div class="margin-top-1-rem"
                        *ngIf="cisSubmission?._accommInstrChanges['accommAvChangedMap'][av.audiovisualCompId]?.audiovisualLength">
                    <div class="fit-content">
                      <div class="old-value fit-content">
                       <span class="fw-bold">Previously:</span>
                       {{cisSubmission?._accommInstrChanges['accommAvChangedMap'][av.audiovisualCompId]?.audiovisualLength?.previousVal || 'N/A'}}
                      </div>
                    </div>
                  </div>
                </div>
                <!-- When should the audiovisual component be administered? -->
                <div class="margin-top-2-rem margin-left-2rem">
                                      <span class="fw-bold">When the AV component be administered:</span> <br>
                                                                   {{av.audiovisualWhenShouldBeAdmin || 'N/A'}}
                  <!-- previous value -->
                  <div class="margin-top-1-rem"
                        *ngIf="cisSubmission?._accommInstrChanges['accommAvChangedMap'][av.audiovisualCompId]?.audiovisualWhenShouldBeAdmin">
                    <div class="old-value fit-content">
                       <span class="fw-bold">Previously:</span>
                       {{cisSubmission?._accommInstrChanges['accommAvChangedMap'][av.audiovisualCompId]?.audiovisualWhenShouldBeAdmin?.previousVal || 'N/A'}}
                    </div>
                  </div>
                </div>

              <!-- Audiovisual Component -->
              <div class="margin-top-2-rem"> <span class="fw-bold">Type:</span>
                                             {{audiovisualMap.get(av.audiovisualCompId)?.audiovisualComp}}
              </div>

              <!-- Specify further details (for Other AV only) -->
              <div class="margin-top-2-rem margin-left-2rem">
                                     <span class="fw-bold">Further Details, if Other:</span> <br>
                                                                   {{av.audiovisualDetailsForOther || 'N/A'}}
                <!-- previous value -->
                <div class="margin-top-1-rem"
                      *ngIf="cisSubmission?._accommInstrChanges['accommAvChangedMap'][av.audiovisualCompId]?.audiovisualDetailsForOther">
                  <div class="old-value fit-content">
                      <span class="fw-bold">Previously:</span>
                      {{cisSubmission?._accommInstrChanges['accommAvChangedMap'][av.audiovisualCompId]?.audiovisualDetailsForOther?.previousVal || 'N/A'}}
                  </div>
                </div>
              </div>

                <!-- How long should each [i.e. comp: slide/clip] be shown? -->
                <div class="margin-top-2-rem margin-left-2rem">
                                     <span class="fw-bold">How long each slide/clip should be shown:</span> <br>
                                                                   {{av.audiovisualHowLongToShow || 'N/A'}}
                  <!-- previous value -->
                  <div class="margin-top-1-rem"
                        *ngIf="cisSubmission?._accommInstrChanges['accommAvChangedMap'][av.audiovisualCompId]?.audiovisualHowLongToShow">
                    <div class="old-value fit-content">
                      <span class="fw-bold">Previously:</span>
                      {{cisSubmission?._accommInstrChanges['accommAvChangedMap'][av.audiovisualCompId]?.audiovisualHowLongToShow.previousVal || 'N/A'}}
                    </div>
                  </div>
                </div>
                <!-- How many times should each [i.e. comp: slide/clip] be shown? -->
                <div class="margin-top-2-rem margin-left-2rem">
                                      <span class="fw-bold">How many times each slide/clip should be shown:</span> <br>
                                                                   {{av.audiovisualHowManyTimesToShow || 'N/A'}}
                  <!-- previous value -->
                  <div class="margin-top-1-rem"
                        *ngIf="cisSubmission?._accommInstrChanges['accommAvChangedMap'][av.audiovisualCompId]?.audiovisualHowManyTimesToShow">
                    <div class="old-value fit-content">
                       <span class="fw-bold">Previously:</span>
                      {{cisSubmission?._accommInstrChanges['accommAvChangedMap'][av.audiovisualCompId]?.audiovisualHowManyTimesToShow?.previousVal || 'N/A'}}
                    </div>
                  </div>
                </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- === Assessment Materials Card === -->
  <div class="margin-top-2-rem">
    <div class="row">
      <div class="col-9">
        <div class="card">
          <div class="card-body">
            <h2 class="card-title">Assessment Materials</h2>
            <p class="card-text">

            <!-- File Upload -->
            <div class="margin-bottom-2-rem"> <span class="fw-bold">File Upload:</span>
                                {{cisSubmission.requiresFileUpload === true ? 'Yes' : 'No'}}
              <!-- previous value -->
              <span class="old-value margin-left-1rem" *ngIf="propValMap?.requiresFileUpload">
                 <span class="fw-bold">Previously:</span>
                                            {{convertBooleanStr(propValMap?.requiresFileUpload.previousVal)}}
              </span>
            </div>

            <!-- *** Assessment File(s) *** -->
            <div class="margin-top-2-rem">
              <h3>Assessment File(s)</h3>

              <!-- File(s) Removed -->
              <div class="old-value fit-content margin-bottom-2-rem" *ngIf="cisSubmission._accommInstrChanges?.filesDeleted?.length ">
                <div class="fw-bold">Removed from CIS (Still in SharePoint):</div>
                  <ul>
                    <li *ngFor="let deletedLink of cisSubmission._accommInstrChanges?.filesDeleted">
                      {{deletedLink.documentLocation}}
                    </li>
                  </ul>
              </div>
            </div>

            <div *ngFor="let file of cisSubmission.files, index as i;">
              <!-- File # -->
              <div class="margin-top-4-rem">
                <h3>File {{i + 1}}
                  <!-- Mark newly added files -->
                  <span class="old-value margin-left-1rem" *ngIf="isFileAdded(file.documentId)">
                    <span class="fw-bold">Added</span>
                  </span>
              </h3>
              </div>
              <!-- Name -->
              <div> <span class="fw-bold">Name:</span> {{file.documentName}} </div>
              <!-- Location -->
              <div class="margin-top-2-rem"> <span class="fw-bold">Location:</span> {{file.documentLocation}} </div>
              <!-- Number of Pages -->
              <div class="margin-top-2-rem"> <span class="fw-bold">Number of Pages:</span> {{file.noOfPages}} </div>
              <!-- Document Modified Date -->
              <div class="margin-top-2-rem margin-bottom-2-rem"> <span class="fw-bold">Document Modified Date:</span>
                                             {{file.documentModifiedDate | date: 'yyyy-MM-dd'}}</div>
            </div>

            <!-- *** Printing Options *** -->
            <div class="margin-top-4-rem">
              <h3>Printing Options</h3>
              <!-- Using Crowdmark -->
              <div><span class="fw-bold">Using Crowdmark:</span>
                                         {{cisSubmission.printOptions?.crowdMark === true ? 'Yes' :
                                             cisSubmission.printOptions?.crowdMark === false ? 'No' : 'N/A'}}
                <!-- previous value -->
                <span class="old-value margin-left-1rem" *ngIf="propValMap?.printOptions_crowdMark">
                  <span class="fw-bold">Previously:</span>
                                             {{convertBooleanStr(propValMap?.printOptions_crowdMark.previousVal)}}
                </span>
              </div>
              <!-- Page Size -->
              <div class="margin-top-2-rem"> <span class="fw-bold">Page Size:</span>
                                                 {{Utils.printPageSize(cisSubmission.printOptions?.pageSize)}}
                <!-- previous value -->
                <span class="old-value margin-left-1rem" *ngIf="propValMap?.printOptions_pageSize">
                  <span class="fw-bold">Previously:</span>
                                             {{Utils.printPageSize(propValMap?.printOptions_pageSize.previousVal)}}
                </span>
              </div>
              <!-- Print 2-Sided -->
              <div class="margin-top-2-rem"> <span class="fw-bold">Print 2-Sided:</span>
                                         {{cisSubmission.printOptions?.twoSided === true ? 'Yes' :
                                             cisSubmission.printOptions?.twoSided === false ? 'No' : 'N/A'}}
                <!-- previous value -->
                <span class="old-value margin-left-1rem" *ngIf="propValMap?.printOptions_twoSided">
                  <span class="fw-bold">Previously:</span>
                                             {{convertBooleanStr(propValMap?.printOptions_twoSided.previousVal)}}
                </span>
              </div>
              <!-- Color Settings -->
              <div class="margin-top-2-rem"> <span class="fw-bold">Color Settings:</span>
                                                 {{Utils.printColour(cisSubmission.printOptions?.colorSettings)}}
                <!-- previous value -->
                <span class="old-value margin-left-1rem" *ngIf="propValMap?.printOptions_colorSettings">
                  <span class="fw-bold">Previously:</span>
                                             {{Utils.printColour(propValMap?.printOptions_colorSettings.previousVal)}}
                </span>
              </div>
              <!-- Special Instructions for Printing -->
              <div class="margin-top-2-rem"> <span class="fw-bold">Special Instructions for Printing:</span><br>
                                                                     {{cisSubmission.specialPrintInstructions || 'N/A'}}
                <!-- previous value -->
                <div class="margin-top-1-rem" *ngIf="propValMap?.specialPrintInstructions">
                  <div class="old-value fit-content">
                     <span class="fw-bold">Previously:</span>
                                             {{propValMap?.specialPrintInstructions.previousVal || 'N/A'}}
                  </div>
                </div>
              </div>
            </div>

            <!-- *** Video URLs *** -->
            <div class="margin-top-4-rem">
              <h3>Video URLs</h3>

              <!-- UrLs Removed -->
              <div class="old-value fit-content margin-bottom-2-rem" *ngIf="cisSubmission._accommInstrChanges?.linkDeleted?.length ">
                <div class="fw-bold">Removed:</div>
                  <ul>
                    <li *ngFor="let deletedLink of cisSubmission._accommInstrChanges.linkDeleted">
                      {{deletedLink.documentLocation || 'N/A'}}
                    </li>
                  </ul>
              </div>

              <div *ngFor="let link of cisSubmission.links, index as i;">
                <!-- Link # -->
                <div class="margin-top-4-rem">
                  <h4>Link {{i + 1}}
                    <!-- Mark newly added links -->
                    <span class="old-value margin-left-1rem" *ngIf="isLinkAdded(link.documentId)">
                    <span class="fw-bold">Added</span>
                  </span>
                  </h4>
                </div>
                <!-- Location -->
                <div> <span class="fw-bold">Location:</span> {{link.documentLocation || 'N/A'}} </div>
                <!-- Link Modified Date -->
                <div class="margin-top-2-rem margin-bottom-2-rem"> <span class="fw-bold">Link Modified Date:</span>
                                                  {{link.documentModifiedDate | date: 'yyyy-MM-dd'}}
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div> <!-- end of cards -->

  <!-- Review Notes -->
  <div class="margin-top-4-rem"> <h2>Review Notes</h2> </div>

  <!-- Display existing ATS notes table -->
  <div class="margin-top-2-rem">
    <div class="row">
      <div class="col-9">
        <app-review-notes-table [reviewNotes]="cisSubmission._atsNotes"></app-review-notes-table>
      </div>
    </div>
  </div>

  <!-- Notes for this Submission (Optional) -->
  <div class="margin-top-2-rem"> <span class="fw-bold">Notes for this Submission (Optional)</span></div>

  <div>
    <div class="col-9">
        <textarea class="" id="atsCisSubmissionNotes"
                  [formControl]="atsCisSubmissionNotesCtrl"
                  rows='2' cols='95'>
        </textarea>
    </div>
  </div>

  <div class="margin-top-1-rem">
    <sis-button size="small" (clicked)="addAtsNote()">Add Note</sis-button>
  </div>

  <!-- Buttons -->
  <div class="margin-top-4-rem" *ngIf="cisSubmission._reviewer && cisSubmission._reviewer === loggedInUser">
    <span class="margin-right-2rem" *ngIf="cisSubmission._reviewStatus != ReviewStatusCode.REVIEWED">
      <sis-button size="medium"
                  (clicked)="setReviewStatus(cisSubmission.atsTestDefinitionId, ReviewStatusCode.REVIEWED)">
        Mark as Reviewed
      </sis-button>&nbsp;
    </span>

    <span class="margin-right-2rem" *ngIf="cisSubmission._reviewStatus != ReviewStatusCode.NOT_REVIEWED">
      <sis-button size="medium"
                  (clicked)="setReviewStatus(cisSubmission.atsTestDefinitionId, ReviewStatusCode.NOT_REVIEWED)">
        Mark as Not Reviewed
      </sis-button>&nbsp;
    </span>

    <span class="margin-right-2rem">
      <ng-template #dropdownBodyTemplate>
        <button *ngIf="cisSubmission._reviewStatus != ReviewStatusCode.PENDING_CONFIRMATION"
                (click)="setReviewStatus(cisSubmission.atsTestDefinitionId, ReviewStatusCode.PENDING_CONFIRMATION)">
          Pending Confirmation
        </button>
        <button *ngIf="cisSubmission._reviewStatus != ReviewStatusCode.PENDING_CONFIRMATION_AWAITING_FILE"
                (click)="setReviewStatus(cisSubmission.atsTestDefinitionId, ReviewStatusCode.PENDING_CONFIRMATION_AWAITING_FILE)">
          Pending Confirmation - Awaiting File
        </button>
      </ng-template>
      <sis-button
        size="medium"
        [dropdownBodyTemplate]="dropdownBodyTemplate"
      >
        Mark as Pending Confirmation
      </sis-button>
    </span>

  </div>

  <!-- Back to CIS Submissions Dashboard -->
  <div class="margin-top-4-rem">
    <a [routerLink]="['/cis-submissions']">Back to CIS Submissions Dashboard</a>
  </div>
</div>
